import addBatchPayments from './addBatchPayments';
import advanceSearch from './advanceSearch';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../../utility/url.utility';
export default {
  name: 'batchPayments',
  components: {
    addBatchPayments,
    advanceSearch,
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getPaymentBatchList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPaymentBatchList();
    }
  },
  data() {
    return {
      payload: null,
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      responseMsg: '',
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showBatchBtn: false,
      showBatchPaymentModal: false,
      batchPaymentDetails: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      paymentDate: [],
      showValueSetModal: false,
      parent_value_set_id: null,
      batchName: '',
      showAdvSearchModal: false,
      selectedBatchDetails: null,
      apInvoiceBatch: null,
      batchPaymentData: [],
      batchPaymentFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'payment_batch_name',
          label: 'Payment Batch'
        },
        {
          key: 'batch_payment_amount',
          label: 'Batch Amount'
        },
        {
          key: 'ap_invoice_batch',
          label: 'Invoice Batch No.'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'project_name',
          label: 'Project'
        },
        {
          key: 'schedule_type_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'payment_status_meaning',
          label: 'Payment Status'
        },
        {
          key: 'party_name'
        },
        {
          key: 'party_type'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'payment_group_meaning',
          label: 'Payment Group'
        },
        {
          key: 'payment_mode_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'payment_priority'
        },
        {
          key: 'payment_date'
        },
        {
          key: 'due_date_from'
        },
        {
          key: 'due_date_to'
        },
        {
          key: 'remarks'
        },
        {
          key: 'request_id',
          label: 'Request'
        }
      ],
      batchPaymentStatus: null,
      disabledFlag: false,
      timeout: null,
      payBatchId: null
    };
  },
  validations: {
    leName: {
      text: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideBatchPaymentModal(true);
          this.batchPaymentDetails = null;
        }
        if (actionName === 'download' && !this.showBatchPaymentModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'payable/getPaymentBatchGrid',
            'batch-payments',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getPaymentBatchList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.leName.value,
          ou_id: this.ouName.value,
          batch_name: this.batchName,
          ap_invoice_batch: this.apInvoiceBatch,
          payment_status_vset: this.batchPaymentStatus,
          payment_from_date: commonHelper.formattedDate(this.paymentDate[0]),
          payment_to_date: commonHelper.formattedDate(this.paymentDate[1])
        };
        this.loader = true;
        this.$store
          .dispatch('payable/getPaymentBatchGrid', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.batchPaymentData = response.data.data.page.map(elem => {
                elem.select = false;
                return elem;
              });
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    disabledButton() {
      this.disabledFlag = true;
      this.timeout = setTimeout(() => {
        this.disabledFlag = false;
      }, 20000);
    },
    selectBatch(item) {
      this.disabledFlag = false;
      this.selectedBatchDetails = Object.assign({}, item);
      this.payBatchId = item.payment_batch_id;
      this.showBatchBtn = true;
    },
    rowSelected(item) {
      if (
        item.payment_status_vset !== 'BUILDING' &&
        item.payment_status_vset !== 'CONFIRMING' &&
        item.payment_status_vset !== 'CANCELLED NO PAYMENTS'
      ) {
        this.batchPaymentDetails = item;
        this.showHideBatchPaymentModal(true);
      }
    },
    updateBatchStatus(eventCall) {
      // this.disabledFlag = true;
      const payload = {
        event_call: eventCall,
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        ou_id: this.ouName.value ? this.ouName.value : '',
        batch_name: this.batchName,
        payment_batch_id: this.selectedBatchDetails.payment_batch_id
      };
      this.disabledButton();
      this.$store
        .dispatch('payable/updateBatchPaymentStatus', payload)
        .then(response => {
          if (response.status === 200) {
            this.showAlert();
            this.isSuccess = true;
            this.showBatchBtn = false;
            this.responseMsg = response.data.message;
            if (response.data.data) {
              this.getPaymentBatchList();
            }
          } else {
            this.showAlert();
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert();
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        })
        .finally(() => {
          // this.disabledFlag = false;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      // this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.ouName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME) {
        this.apInvoiceBatch = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BATCH_PAYMENT_STATUS
      ) {
        this.batchPaymentStatus = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.leName = {
        value: null,
        text: null
      };
      this.ouName = {
        value: null,
        text: null
      };
      this.batchName = null;
      this.parent_value_set_id = null;
      this.apInvoiceBatch = null;
      this.batchPaymentStatus = null;
      this.paymentDate = [];
      this.batchPaymentData = [];
      this.showBatchBtn = null;
      this.totalRows = null;
      this.currentPage = 1;
    },
    showHideBatchPaymentModal(flag) {
      this.showBatchPaymentModal = flag;
    },
    showHideAdvSearchModal(flag) {
      this.showAdvSearchModal = flag;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.leName.text) {
        this.leName = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.ouName.text) {
        this.ouName = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.apInvoiceBatch) {
        this.apInvoiceBatch = null;
      } else if (vsetCode === this.batchPaymentStatus) {
        this.batchPaymentStatus = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.timeout);
  }
};
