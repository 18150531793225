import { format } from 'date-fns';
import { ModelSelect } from 'vue-search-select';
// import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '../../../../admin/responsibility/searchModule';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import {
  required,
  integer,
  maxValue,
  minValue
} from 'vuelidate/lib/validators';
export default {
  name: 'AddBatchPayment',
  components: {
    DatePicker,
    party,
    ModelSelect,
    ModulesList
  },
  props: ['batchPaymentDetails'],
  watch: {
    'paymentMethod.text'() {
      this.setBankDetails();
    }
  },
  data() {
    return {
      depVsetParam: null,
      payload: null,
      loader: false,
      unsubscribe: null,
      showModal: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      tab: null,
      batchName: null,
      totalElement: 0,
      scheduleType: {
        value: null,
        text: null
      },
      batchId: 0,
      paymentPriority: 99,
      showPartyModal: false,

      selectedModule: {
        name: null,
        id: null
      },
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentBasedBanks: [],
      paymentDate: null,
      paymentMode: {
        value: null,
        text: null
      },
      bankName: null,
      branchName: null,
      accountName: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      lastUpdatedLogin: null,
      lastUpdatedBy: null,
      // dueFromToDate: [],
      dueDateFrom: null,
      dueDateTo: null,
      payGroup: {
        value: null,
        text: null
      },
      party: {
        value: null,
        text: null
      },
      partyType: null,
      status: 'NEW',
      remarks: null,
      ap_invoice_batch: null,
      creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
      createdBy: null,
      invoiceDetails: [],
      paymentDetails: [],
      projectType: ['LEASE', 'FMS'],
      paymentList: [
        {
          invoice: 'invoice',
          batch_name: 'Test',
          payment_type: 'N/A',
          instrument_status: 'Negotiable',
          invoicesList: [
            {
              module_name: 'N/A'
            }
          ]
        }
      ],
      paymentFields: [
        {
          key: 'invoice'
        },
        {
          key: 'selection_flag',
          label: 'Select'
        },
        {
          key: 'status_flag',
          label: 'Status'
        },
        {
          key: 'payment_document_no',
          label: 'Payment Doc No.'
        },
        {
          key: 'payment_ref_no'
        },
        {
          key: 'party_name'
        },
        {
          key: 'party_type'
        },
        {
          key: 'party_site_name',
          label: 'Party Site'
        },
        {
          key: 'party_site_address'
        },
        {
          key: 'instrument_status_meaning',
          label: 'Instrument Status'
        },
        {
          key: 'payment_amount'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'exchange_rate_type'
        },
        {
          key: 'actual_bank_exchange_rate'
        },
        {
          key: 'actual_bank_exchange_rate_date'
        },
        {
          key: 'payment_date'
        },
        {
          key: 'beneficiary_name'
        },
        {
          key: 'bank_account_number',
          label: 'Bank Acc No.'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'utr_no',
          label: 'UTR No.'
        },
        {
          key: 'void_date'
        },
        {
          key: 'void_gl_date',
          label: 'Void GL Date'
        },
        {
          key: 'remarks'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'error_msg'
        }
      ],
      invoicesList: [],
      invoicesFields: [
        {
          key: 'selection_flag',
          label: 'Select'
        },
        {
          key: 'status_flag',
          label: 'Status'
        },
        {
          key: 'invoice_number'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'schedule_number'
        },
        {
          key: 'schedule_line_type_meaning',
          label: 'Schedule Line Type'
        },
        {
          key: 'schedule_amount'
        },
        {
          key: 'original_amount',
          label: 'Outstanding Amount'
        },
        {
          key: 'payment_amount',
          label: 'Paid in this Payment'
        },
        {
          key: 'error_msg'
        }
      ],
      selectPaymentStatus: {
        value: null,
        text: null
      },
      paymentStatusList: [
        {
          value: 'S',
          text: 'Success'
        },
        {
          value: 'E',
          text: 'Error'
        },
        {
          value: 'P',
          text: 'Validated'
        }
      ],
      paymentParty: {
        id: null,
        name: null
      },
      paymentPartyType: null,
      amountFrom: null,
      amountTo: null,
      actionType: null,
      paymentAmount: 0,
      paySeries: {
        value: null,
        text: null
      }
    };
  },
  validations: {
    leName: { text: { required } },
    ouName: { text: { required } },
    project: { text: { required } },
    paySeries: { text: { required } },
    paymentMethod: { text: { required } },
    paymentMode: { text: { required } },
    paymentPriority: {
      required,
      integer,
      minValue: minValue(1),
      maxValue: maxValue(99)
    }
  },
  mounted() {
    if (this.batchPaymentDetails) {
      this.getBatchPaymentHdrDetails(this.batchPaymentDetails);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          if (this.status === 'NEW') {
            this.addEditPaymentMethod();
          } else if (this.status === 'BUILD') {
            this.updateInvoicesByPaymentId();
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (
          actionName === 'download' &&
          this.batchId &&
          this.paymentList.length > 0
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          this.downloadExcel.downloadDetails(
            downloadpayload,
            'payable/getPaymentsByBatchPaymentId',
            'payments',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    addEditPaymentMethod() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          // due_date_from: commonHelper.formattedDate(this.dueFromToDate[0]),
          // due_date_to: commonHelper.formattedDate(this.dueFromToDate[1]),
          due_date_from: this.dueDateFrom,
          due_date_to: this.dueDateTo,
          module_id: this.selectedModule.id,
          le_id: this.leName.value,
          ou_id: this.ouName.value,
          party_id: this.party.value,
          party_type: this.partyType,
          payment_batch_id: this.batchId,
          payment_date: this.paymentDate,
          payment_group_vset: this.payGroup.value,
          payment_method_id: this.paymentMethod.value,
          payment_mode_vset: this.paymentMode.value,
          payment_priority: this.paymentPriority,
          payment_status_vset: this.status,
          remarks: this.remarks,
          schedule_type: this.scheduleType.value,
          ap_invoice_batch: this.ap_invoice_batch,
          project_id: this.project.value,
          payment_series_id: this.paySeries.value,
          project_type:
            this.selectedModule.name === 'Lease Management'
              ? this.projectType[0]
              : this.projectType[1]
        };
        this.loader = true;
        this.$store
          .dispatch('payable/addEditPaymentBatch', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.batchId = response.data.data.payment_batch_id;
              this.batchName = response.data.data.payment_batch_name;
              this.status = response.data.data.payment_status_vset;
              this.responseMsg = response.data.message;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    getBatchPaymentHdrDetails(batchPaymentHdr) {
      this.selectedModule.id = batchPaymentHdr.module_id;
      this.selectedModule.name = batchPaymentHdr.module_name;
      this.batchId = batchPaymentHdr.payment_batch_id;
      this.batchName = batchPaymentHdr.payment_batch_name;
      this.scheduleType = {
        value: batchPaymentHdr.schedule_type,
        text: batchPaymentHdr.schedule_type_meaning
      };
      this.paymentPriority = batchPaymentHdr.payment_priority;
      this.paymentMethod = {
        value: batchPaymentHdr.payment_method_id,
        text: batchPaymentHdr.payment_method
      };
      this.paymentMethodList = [
        {
          value: batchPaymentHdr.payment_method_id,
          text: batchPaymentHdr.payment_method
        }
      ];
      this.paymentDate = batchPaymentHdr.payment_date;
      this.paymentMode = {
        value: batchPaymentHdr.payment_mode_vset,
        text: batchPaymentHdr.payment_mode_meaning
      };
      this.leName = {
        value: batchPaymentHdr.le_id,
        text: batchPaymentHdr.le_name
      };
      this.ouName = {
        value: batchPaymentHdr.ou_id,
        text: batchPaymentHdr.ou_name
      };
      this.project = {
        value: batchPaymentHdr.project_id,
        text: batchPaymentHdr.project_name
      };
      this.dueDateFrom = batchPaymentHdr.due_date_from;
      this.dueDateTo = batchPaymentHdr.due_date_to;
      this.payGroup = {
        value: batchPaymentHdr.payment_group_vset,
        text: batchPaymentHdr.payment_group_meaning
      };
      this.party = {
        value: batchPaymentHdr.party_id,
        text: batchPaymentHdr.party_name
      };
      this.partyType = batchPaymentHdr.party_type;
      this.status = batchPaymentHdr.payment_status_vset;
      this.remarks = batchPaymentHdr.remarks;
      this.ap_invoice_batch = batchPaymentHdr.ap_invoice_batch;
      this.paymentAmount = batchPaymentHdr.batch_payment_amount
        ? batchPaymentHdr.batch_payment_amount
        : 0;
      this.getPaymentMethodsList();
      if (this.status !== 'NEW') {
        this.getPaymentsByBatchPaymentId();
      }
    },
    getPaymentsByBatchPaymentId() {
      this.payload = {
        batchPaymentId: this.batchId,
        requestParams: {
          status: this.selectPaymentStatus.value,
          party_id: this.paymentParty.id,
          party_type: this.paymentPartyType,
          payment_amount_from: this.amountFrom,
          payment_amount_to: this.amountTo
        }
      };
      this.loader = true;
      this.$store
        .dispatch('payable/getPaymentsByBatchPaymentId', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.paymentList = results;
            if (this.paymentList && this.paymentList.length > 0) {
              this.totalElement = this.paymentList.length;
            } else {
              this.totalElement = 0;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getInvoicesByPaymentId(item, paymentArray) {
      const payload = {
        payment_hdr_id: item.payment_hdr_id,
        paymentBatchId: {
          payment_batch_id: this.batchPaymentDetails.payment_batch_id
        }
      };
      this.loader = true;
      this.$store
        .dispatch('payable/getInvoicesByPaymentId', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const invoiceResults = response.data.data;
            item.invoicesList = invoiceResults.invoice_details;
            paymentArray.toggleDetails();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    viewInvoices(item, paymentArray) {
      if (
        !paymentArray.detailsShowing &&
        item.invoicesList &&
        item.invoicesList.length > 0
      ) {
        paymentArray.toggleDetails();
      } else {
        this.getInvoicesByPaymentId(item, paymentArray);
      }
    },
    updateInvoicesByPaymentId() {
      const payload = {
        batchPaymentId: this.batchId,
        requestBody: this.paymentList.map(elem => {
          return {
            payment_batch_id: elem.payment_batch_id,
            payment_hdr_id: elem.payment_hdr_id,
            selection_flag: elem.selection_flag,
            invoice_details: elem.invoicesList
              ? elem.invoicesList.map(data => {
                  return {
                    payment_amount: data.payment_amount,
                    payment_dtl_id: data.payment_dtl_id,
                    selection_flag: data.selection_flag,
                    status_flag: data.status_flag
                  };
                })
              : null
          };
        })
      };
      this.loader = true;
      this.$store
        .dispatch('payable/updateInvoicesBasedOnStatus', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.paymentList = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectInvoices(event, invoiceIndex, paymentIndex) {
      this.invoiceIndex = invoiceIndex;
      this.paymentIndex = paymentIndex;
      this.paymentList[paymentIndex].invoicesList[
        invoiceIndex
      ].selection_flag = event;
      if (!this.isAllInvoicesCheckedFun(paymentIndex)) {
        this.paymentList[paymentIndex].selection_flag = false;
      }
    },
    isAllInvoicesCheckedFun(paymentIndex) {
      const allChecked = this.paymentList[paymentIndex].invoicesList.find(
        key => key.selection_flag
      );
      return allChecked;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.leName.value;
        this.depVsetParam = this.ouName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.parent_value_set_id = this.leName.value;
        this.depVsetParam = this.ouName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_SERIES_AP) {
        this.parent_value_set_id = this.paymentMode.value;
        this.depVsetParam = this.accountId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName.text = item.org_name;
        this.leName.value = item.org_id;
        this.ouName.text = null;
        this.ouName.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName.text = item.org_name;
        this.ouName.value = item.org_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SCHEDULE_LINE_TYPE_VSET
      ) {
        this.scheduleType.text = item.value_meaning;
        this.scheduleType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.paymentMode.text = item.value_meaning;
        this.paymentMode.value = item.value_code;
        this.getPaymentMethodsList();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAY_GROUP_TYPE) {
        this.payGroup.text = item.value_meaning;
        this.payGroup.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME) {
        this.ap_invoice_batch = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_SERIES_AP) {
        this.paySeries.text = item.value_code;
        this.paySeries.value = item.value_set_dtl_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.project.text = item.value_code;
        this.project.value = item.value_set_dtl_id;
        this.paymentMode = {value:null,text:null};
        this.paymentMethod = {value:null,text:null};
        this.bankName = null;
        this.branchName = null;
        this.accountName = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getSelectedPartyCustomer(item) {
      if (this.actionType === 'BATCH_CREATE') {
        this.party.text = item.party_name;
        this.partyType = item.party_type;
      } else if (this.actionType === 'PAYMENT_SEARCH') {
        this.paymentParty.name = item.party_name;
        this.paymentPartyType = item.party_type;
      }
      this.showPartyModal = false;
      if (
        this.partyType === 'CUSTOMER' ||
        this.paymentPartyType === 'CUSTOMER'
      ) {
        this.getCustomerByPartyId(item.party_id);
      } else if (
        this.partyType === 'VENDOR' ||
        this.paymentPartyType === 'VENDOR'
      ) {
        this.getPartyVendorDetails(item.party_id);
      }
    },
    showHidePartyModal(flag, actionType) {
      this.showPartyModal = flag;
      if (flag) {
        this.actionType = actionType;
        this.setCustCode = setTimeout(() => {
          this.eventBus.$emit('userType', 'VEND');
        }, 0);
      }
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            if (this.actionType === 'BATCH_CREATE') {
              this.party.value = response.data.data.customer_id;
            } else if (this.actionType === 'PAYMENT_SEARCH') {
              this.paymentParty.id = response.data.data.customer_id;
            }
          }
        });
    },
    getPartyVendorDetails(partyId) {
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (this.actionType === 'BATCH_CREATE') {
              this.party.value = response.data.data.vendor_id;
            } else if (this.actionType === 'PAYMENT_SEARCH') {
              this.paymentParty.id = response.data.data.vendor_id;
            }
          }
        });
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showModal = false;
    },
    getPaymentMethodsList() {
      const payload = {
        module_id: this.selectedModule.id,
        le_id: this.leName.value,
        receipt_mode_vset_code: this.paymentMode.value,
        project_id: this.project.value
      };
      this.loader = true;
      this.$store
        .dispatch('bankMpngMode/getPaymentMethods', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const typeList = results.map(type => {
              return {
                value: type.party_payment_method_id,
                text: type.payment_methods_name
              };
            });
            this.paymentMethodList = typeList;
            this.paymentBasedBanks = results;
            this.setBankDetails();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      }
    },
    setBankDetails() {
      if (this.paymentBasedBanks && this.paymentBasedBanks.length > 0) {
        let paymentBankDetail = this.paymentBasedBanks.find(key => {
          if (key.payment_methods_name === this.paymentMethod.text) {
            return key;
          }
        });
        this.bankName = paymentBankDetail.bank_name;
        this.branchName = paymentBankDetail.branch_name;
        this.accountName = paymentBankDetail.account_name;
        this.accountId = paymentBankDetail.bank_account_id;
        this.paySeries.text = paymentBankDetail.payment_series_name;
        this.paySeries.value = paymentBankDetail.payment_series_id;
      }
    },
    clearFilters() {
      this.selectPaymentStatus = {
        value: null,
        text: null
      };
      this.paymentParty = {
        id: null,
        name: null
      };
      this.paymentPartyType = null;
      this.amountFrom = null;
      this.amountTo = null;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.leName.value) {
        this.leName = {value:null,text:null}
        this.ouName = {value:null,text:null}
      } else if (vsetCode === this.ouName.value) {
        this.ouName = {value:null,text:null}
      } else if (vsetCode === this.paymentMode.value) {
        this.paymentMode = {value:null,text:null}
      } else if (vsetCode === this.payGroup.value) {
        this.payGroup = {value:null,text:null}
      } else if (vsetCode === this.scheduleType.value) {
        this.scheduleType = {value:null,text:null}
      } else if (vsetCode === this.ap_invoice_batch) {
        this.ap_invoice_batch = null;
      } else if (vsetCode === this.paySeries.value) {
        this.paySeries = {value:null,text:null}
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
