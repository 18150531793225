
import { format } from 'date-fns';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';

export default {
    name: 'AdvanceSearch',
    components: {
        DatePicker
    },
    data() {
        return {
            loader: false,
            batchName: null,
            paymentMethod: null,
            paymentDate: null,
            paymentMode: null,
            unsubscribe: null,
            leName: {
                value: null,
                text: null
            },
            ouName: {
                value: null,
                text: null
            },
            parent_value_set_id: null,
            showValueSetModal: false,
            lastUpdatedLogin: null,
            lastUpdatedBy: null,
            dueFromToDate: null,
            payGroup: null,
            vendor: null,
            status: 'New',
            remarks: null,
            creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
            createdBy: null,
        };
    },
    mounted() { 
    
    },
    methods: {
        openValueSetModal(vsetCode) {
            if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.parent_value_set_id = this.leName.value;
            }
            this.vsetCode = vsetCode;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        selectedvalueSet(item) {
            if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
                this.leName = {
                    value: item.org_id,
                    text: item.org_name
                };
                this.ouName = {
                    value: null,
                    text: null
                }
            } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.ouName = {
                    value: item.org_id,
                    text: item.org_name
                };
            }
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        searchBatchPaymmentsParams() {},
        clearBatchPaymmentsParams(){
            this.leName = {
                value: null,
                text: null
            };
            this.ouName = {
                value: null,
                text: null
            };
            this.batchName = null;
            this.paymentMethod = null;
            this.paymentDate = null;
            this.paymentMode = null;
            this.lastUpdatedLogin = null;
            this.lastUpdatedBy = null;
            this.dueFromToDate = null;
            this.payGroup = null;
            this.vendor = null;
            this.createdBy = null;
            this.remarks = null;
        }  
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};